import React, { useMemo } from 'react'
import { formatMoney } from '../../../utils/numberFormat'
import { Button, Card, Text } from 'tabler-react'
import { useGetRegistrationGroups } from '../hooks/useGetRegistrationGroups'
import { Types } from '../../../types/graphql'

const RegistrationGroupSanctionCard = ({ userRegistrationDetails }) => {
  const { data: groupsData, loading: groupsLoading } = useGetRegistrationGroups(
    {
      registrationId: userRegistrationDetails.user_registrations_id,
      status: 'Active'
    }
  )
  const groups = useMemo(() => {
    if (!groupsLoading && groupsData) {
      return groupsData
    }

    return []
  }, [groupsData, groupsLoading])
  return (
    <Card>
      <Card.Status className="bg-success" />
      {groups.map((group) => (
        <RegistrationGroupSanctionCardItem
          group={group}
          userRegistrationDetails={userRegistrationDetails}
        />
      ))}
    </Card>
  )
}

const RegistrationGroupSanctionCardItem = ({
  userRegistrationDetails,
  group
}: {
  userRegistrationDetails: Types.UserRegistrationsById
  group: Types.Group
}) => {
  return (
    <>
      <Card.Body>
        <h4>{group.name}</h4>
        <span className="float-right">
          <strong>
            {formatMoney(
              group.paymentDetails.sanctionFee / 100,
              userRegistrationDetails.sanction_fee_currency
            )}
          </strong>
        </span>
        <strong>Sanction Fee</strong>
        {group.paymentDetails.sanctionFeeCount > 1 && (
          <span className="text-muted">
            <i className="fe fe-x mx-1 small" />
            {group.paymentDetails.sanctionFeeCount}
          </span>
        )}
      </Card.Body>
      <Card.Footer>
        <Text className="text-muted float-left">
          <Text.Small className="d-block">
            {userRegistrationDetails?.registration_currency +
              ' to ' +
              userRegistrationDetails.sanction_fee_currency}{' '}
            <a href="#">change</a>
          </Text.Small>
          <Text.Small className="d-block">
            {formatMoney(0, userRegistrationDetails.registration_currency) +
              ' = ' +
              formatMoney(
                group.paymentDetails.sanctionFee / 100,
                userRegistrationDetails.sanction_fee_currency
              )}
          </Text.Small>
          <Text.Small className="d-block"><i>
            {formatMoney(1.1339 * userRegistrationDetails.sanction_fee, userRegistrationDetails.registration_currency) +
              ' = ' +
              formatMoney(
                userRegistrationDetails.sanction_fee,
                userRegistrationDetails.sanction_fee_currency
              )}</i>
          </Text.Small>
        </Text>
        <Button.List align="right">
          <Button icon="credit-card" color="success">
            PAY: {userRegistrationDetails.sanction_fee_currency}
          </Button>
        </Button.List>
      </Card.Footer>
    </>
  )
}

export default RegistrationGroupSanctionCard
