import { ApolloError, useMutation } from "@apollo/client";
import { DEFAULT_LIMIT } from "components/constants";
import { Formik } from "formik";
import { ADD_USER_POST } from "graphql/ADD_USER_POST";
import { GET_USER_POSTS } from "graphql/GET_USER_POSTS";
import { SEND_USER_POST_EMAIL } from "graphql/SEND_USER_POST_EMAIL";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import ConfirmationModal from "modules/common/components/modals/ConfirmationModal";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import React, { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Avatar, Button, Text } from "tabler-react";
import * as Yup from "yup";
import FormField from "../../FormField";

const addUserPostValidationSchema = Yup.object().shape({
  content: Yup.string().required("This field is required."),
});

const UserPostForm = () => {
  const { isStudent } = useGetCurrentUserType();
  const { currentUser } = useRootStore();

  const { eventId } = useParams<{ eventId: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [addUserPost] = useMutation(ADD_USER_POST);
  const [sendUserPostEmail, { loading }] = useMutation(SEND_USER_POST_EMAIL, {
    onCompleted: () => {
      toggleModal();
      toast.success("Email sent");
    },
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
  });

  const initValues = {
    camp_id: Number(eventId),
    student_id: isStudent ? currentUser.id : undefined,
    coach_id: !isStudent ? currentUser.id : undefined,
    content: "",
  };

  const renderForm = ({
    values,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
  }) => (
    <>
      <div className="mb-2">
        <span className="float-right mt-4"><strong></strong></span>
          <Avatar
            size="md"
            className="mr-3 float-left mb-2"
            imageURL={
                (currentUser.profile_avatar &&
                  `/api/s3/uploads/${currentUser.profile_avatar}`) ||
                '//www.gravatar.com/avatar?d=mp'
              }
          />
          <strong>{currentUser.first_name} {currentUser.last_name}</strong>
          <Text.Small className="text-muted d-block">Contribute to the discussion...</Text.Small>
      </div>
      <FormField
        type="text"
        multiline={"true"}
        placeholder="Post to this event (visible to confirmed participants and organizers only)"
        name="content"
        rows={values.content ? 2 : 1}
        onChange={handleChange}
        value={values.content}
      />
      {errors.content && values.content && (
        <span className="field-error text-danger">{errors.content}</span>
      )}
      {values.content && (<>
      <Button.List className="mt-4 mb-2" align="right">
        {!isStudent && (
          <Button
            icon="mail"
            color="info"
            type="submit"
            onClick={() => toggleModal()}
            disabled={!values.content}
          >
            POST & SEND
          </Button>
        )}
        <Button
          color="primary"
          type="submit"
          onClick={() => handleSubmit()}
          disabled={!values.content}
        >
          POST
        </Button>
      </Button.List>
      </>)}
      <ConfirmationModal
        title="Post & Email Confirmed Participants"
        content={values.content}
        isSubmitting={loading}
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={async () => {
          await sendUserPostEmail({
            variables: {
              sendUserPostEmailInput: {
                ...values,
              },
            },
            refetchQueries: [
              {
                query: GET_USER_POSTS,
                variables: {
                  userPostOptions: {
                    camp_id: Number(eventId),
                    limit: DEFAULT_LIMIT,
                    offset: 0,
                  },
                },
              },
            ],
          });

          setFieldValue("content", " ");
          resetForm();
        }}
      />
    </>
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={addUserPostValidationSchema}
        onSubmit={async (values, { setFieldValue, resetForm }) => {
          await addUserPost({
            variables: {
              addUserPostInput: {
                ...values,
              },
            },
            refetchQueries: [
              {
                query: GET_USER_POSTS,
                variables: {
                  userPostOptions: {
                    camp_id: Number(eventId),
                    limit: DEFAULT_LIMIT,
                    offset: 0,
                  },
                },
              },
            ],
          });
          setFieldValue("content", " ");
          resetForm();
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  );
};

export default observer(UserPostForm);
