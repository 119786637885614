import { useMutation, useQuery } from '@apollo/client'
import CalendarLink from 'components/Calendar/CalendarLink'
import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'
import { EHostnames } from 'components/constants'
import { EEventTypes } from 'components/enum'
import PaymentsSidebar from 'components/Payments/PaymentsSidebar'
import RegistrationActivityListModal from 'components/Registration/Modals/RegistrationActivityListModal'
import RegistrationStudentDetails from 'components/Registration/RegistrationStudentDetails'
import ConfirmedSlotsModal from 'components/Students/ConfirmedSlotsModal'
import StudentNotesList from 'components/Students/StudentNotesList'
import StudentRecentCamps from 'components/Students/StudentRecentCamps'
import UserRegistrationOption from 'components/User/Forms/UserRegistrationOption'
import UserRegistrationQuestionItem from 'components/User/Forms/UserRegistrationQuestionItem'
import UpdateUserRegistrationOptionModal from 'components/User/Modals/UpdateUserRegistrationOptionModal' //import UserCard from "components/User/Profile/UserCard";
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from 'graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { GET_REGISTRATION_QUESTIONS } from 'graphql/GET_REGISTRATION_QUESTIONS'
import { GET_USER_REGISTRATIONS_BY_IDS } from 'graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { GET_CAMP_REGISTRATION_REQUIREMENTS as GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE } from 'graphql/types/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { GET_REGISTRATION_QUESTIONS as GET_REGISTRATION_QUESTIONS_TYPE } from 'graphql/types/GET_REGISTRATION_QUESTIONS'
import { GET_USER_REGISTRATIONS_BY_IDS as GET_USER_REGISTRATIONS_BY_IDS_TYPE } from 'graphql/types/GET_USER_REGISTRATIONS_BY_IDS'
import { UPDATE_USER_REGISTRATION } from 'graphql/UPDATE_USER_REGISTRATION'
import { useRootStore } from 'hooks'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import CRMMessagesList from 'modules/crm-message/components/lists/CRMMessagesList'
import RegistrationPayments from 'modules/registration/payments/components/RegistrationPayments'
import CampRequirements from 'modules/registration/requirements/components/grids/CampRequirements'
import QRCodeUserRegistration from 'modules/user/registrations/components/buttons/QRCodeUserRegistration'
import UserRegistrationStatusDropdown from 'modules/user/registrations/components/dropdowns/UserRegistrationStatusDropdown'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Card, Form, Grid, Tag, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import { GET_CAMP_COHOSTS } from '../../graphql/GET_CAMP_COHOSTS'
import { Types } from '../../types/graphql'
import { Loading } from '../Loading'
import RegistrationGroups from '../Registration/RegistrationGroups'
import StudentTeamProgressionModal from '../Students/Modal/studentTeamProgressionModal'
import { useGetStudentOverride } from 'modules/user/hooks/user'
import RegistrationGroupSanctionCard from '../../modules/group/components/RegistrationGroupSanctionCard'

enum EModalTypes {
  manualPaymentModal = 'manualPaymentModal',
  slotsConfirmedModal = 'slotsConfirmedModal',
  registrationOptionModal = 'registrationOptionModal'
}

interface IModalStates {
  slotsConfirmedModal: boolean
  registrationOptionModal: boolean
}

const modalInitState = {
  manualPaymentModal: false,
  slotsConfirmedModal: false,
  registrationOptionModal: false
}

interface UserRegistrationProps {
  userRegIdProp?: number
  studentIdProp?: number
}

const UserRegistration = ({
  userRegIdProp,
  studentIdProp
}: UserRegistrationProps) => {
  const { userRegId, studentId } = useParams<{
    userRegId: string
    studentId: string
  }>()
  const { isAdmin, isCoachSuperAdmin, isStudent } = useGetCurrentUserType()
  const { currentCoachTeam } = useRootStore()

  const { loading, error, data } = useQuery<GET_USER_REGISTRATIONS_BY_IDS_TYPE>(
    GET_USER_REGISTRATIONS_BY_IDS,
    {
      variables: {
        userRegistrationsId: Number(userRegId ?? userRegIdProp)
      },
      fetchPolicy: 'network-only'
    }
  )

  const { loading: loadingTeams, data: dataTeams } = useQuery(
    GET_CAMP_COHOSTS,
    {
      variables: {
        campId: Number(data?.getUserRegistrationsByIds.camp_id)
      },
      skip:
        isAdmin ||
        isStudent ||
        isCoachSuperAdmin ||
        !data?.getUserRegistrationsByIds.camp_id,
      fetchPolicy: 'network-only'
    }
  )

  const isCurrentTeamCoHost = useMemo(() => {
    if (loadingTeams || !dataTeams?.getCampCoHosts) return false
    return dataTeams?.getCampCoHosts
      .filter((team) => team.is_active)
      .some((team) => team.id === currentCoachTeam?.id)
  }, [dataTeams, loadingTeams])

  if (loading || loadingTeams) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  return (
    <UserRegistrationInner
      userRegistrationDetails={data?.getUserRegistrationsByIds}
      studentId={Number(studentId ?? studentIdProp)}
      userRegId={Number(data?.getUserRegistrationsByIds.user_registrations_id)}
      isCohost={isCurrentTeamCoHost}
    />
  )
}

const UserRegistrationInner = ({
  userRegistrationDetails,
  studentId,
  userRegId,
  isCohost
}) => {
  const {
    isAdmin,
    isCoach,
    isCoachSuperAdmin,
    isCoachAdmin,
    isEventOrganizer,
    isOrg,
    isStudent
  } = useGetCurrentUserType()

  const { totalPaid, outstanding } = userRegistrationDetails.paymentDetail

  const { currentUser, domainData } = useRootStore()
  const isStudentOverride = useGetStudentOverride(studentId)
  const [isModalOpen, setIsModalOpen] = useState<IModalStates>(modalInitState)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState<boolean>(false)

  const [isEditting, setIsEditting] = useState<boolean>()
  const [isEdittingRating, setIsEdittingRating] = useState<boolean>()

  const handleToggleModal = (mType: EModalTypes) =>
    setIsModalOpen({
      ...isModalOpen,
      [mType]: !isModalOpen[mType]
    })

  const [rating, setRating] = useState(userRegistrationDetails.rating)
  const [quantity, setQuantity] = useState(
    userRegistrationDetails.jump_quantity
  )

  const { data: questionData } = useQuery<GET_REGISTRATION_QUESTIONS_TYPE>(
    GET_REGISTRATION_QUESTIONS,
    {
      variables: {
        filter: {
          userRegistrationId: userRegId,
          campId: userRegistrationDetails.camp_id,
          teamId: userRegistrationDetails.team_id
        }
      }
    }
  )

  const activeQuestions = useMemo(() => {
    return (
      questionData?.registrationQuestions.filter((question) => {
        const isLocationMatch = question.location_id
          ? Number(question.location_id) ===
            userRegistrationDetails.campLocation
          : true
        const isSportsTypeMatch = question.sport_type_id
          ? question.sport_type_id ===
            Number(userRegistrationDetails.sportTypeId)
          : true
        return question.is_exist && isLocationMatch && isSportsTypeMatch
      }) ?? []
    )
  }, [userRegistrationDetails, questionData])

  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    onCompleted: () => {
      toast.success('Registration updated!')
    },
    onError: () => {
      toast.error('Something went wrong!')
    },
    refetchQueries: ['GET_USER_REGISTRATIONS_BY_IDS']
  })

  const updateJumpQuantity = () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: userRegId.toString(),
          jump_quantity: quantity,
          coach_id: currentUser.id
        }
      }
    })
    setIsEditting(false)
  }

  const { data: campRequirementsData } =
    useQuery<GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE>(
      GET_CAMP_REGISTRATION_REQUIREMENTS,
      {
        variables: {
          team_id: userRegistrationDetails?.team_id,
          camp_id: Number(userRegistrationDetails?.camp_id)
        }
      }
    )

  const campRequirements = useMemo(() => {
    return (
      campRequirementsData?.campRegistrationRequirements
        .filter((r) => r.is_exist)
        .map((r) => r.requirement_type_slug) ?? []
    )
  }, [campRequirementsData?.campRegistrationRequirements])

  const hasPaymentsHistory = userRegistrationDetails.payments.length > 0

  const updateRating = async () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: userRegId,
          rating: rating,
          coach_id: currentUser.id
        }
      }
    })
    setIsEdittingRating(false)
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <>
      <Helmet>
        <title>
          {domainData.name +
            ' | ' +
            userRegistrationDetails?.first_name +
            ' ' +
            userRegistrationDetails?.last_name}{' '}
          - {userRegistrationDetails?.camp_name}
        </title>
      </Helmet>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={4} lg={4}>
          <RegistrationStudentDetails
            campRequirements={campRequirements}
            userRegistrationDetails={userRegistrationDetails}
          />

          {/* Display QR Code only to student, and only after event has started, and before it has ended */}
          {isAdmin ||
            isCoachSuperAdmin ||
            (isStudentOverride &&
              moment(userRegistrationDetails?.end).isBefore() && (
                <QRCodeUserRegistration
                  userRegistration={userRegistrationDetails}
                />
              ))}
          {userRegistrationDetails &&
            !userRegistrationDetails.registration_groups &&
            (userRegistrationDetails?.status === 'Confirmed' ||
              userRegistrationDetails?.status === 'Accepted' ||
              userRegistrationDetails?.status === 'Conditionally Approved' ||
              userRegistrationDetails.eventTypeId === '2') && (
              <PaymentsSidebar
                userRegistrationDetails={userRegistrationDetails}
                isCohost={isCohost}
              />
            )}

          {userRegistrationDetails.eventTypeId === EEventTypes.competition &&
            !!userRegistrationDetails.sanction_fee &&
            userRegistrationDetails.sanction_fee_currency !==
              userRegistrationDetails.registration_currency &&
              userRegistrationDetails.registration_groups ? (
              <RegistrationGroupSanctionCard
                userRegistrationDetails={userRegistrationDetails}
              />
            ) : ''}

          {(userRegistrationDetails.eventTypeId === EEventTypes.competition &&
            !!userRegistrationDetails.sanction_fee &&
            userRegistrationDetails.sanction_fee_currency !==
              userRegistrationDetails.registration_currency &&
              !userRegistrationDetails.registration_groups) ? (
              <Card>
                <Card.Status className="bg-success" />
                <Card.Body> 
                  <span className="float-right">
                    <strong>
                      {formatMoney(
                        userRegistrationDetails.sanction_fee,
                        userRegistrationDetails.sanction_fee_currency
                      )}
                    </strong>
                  </span>
                  <strong>Sanction Fee</strong>
                </Card.Body>
                <Card.Footer>
                  <Text className="text-muted float-left">
                    <Text.Small className="d-block">
                      {userRegistrationDetails?.registration_currency +
                        ' to ' +
                        userRegistrationDetails.sanction_fee_currency}{' '}
                      <a href="#">change</a>
                    </Text.Small>
                    <Text.Small className="d-block">
                      {formatMoney(0, userRegistrationDetails.registration_currency) +
                        ' = ' +
                        formatMoney(
                          userRegistrationDetails.sanction_fee,
                          userRegistrationDetails.sanction_fee_currency
                        )}
                    </Text.Small>
                    <Text.Small className="d-block"><i>
                      {formatMoney(1.1339 * userRegistrationDetails.sanction_fee, userRegistrationDetails.registration_currency) +
                        ' = ' +
                        formatMoney(
                          userRegistrationDetails.sanction_fee,
                          userRegistrationDetails.sanction_fee_currency
                        )}</i>
                    </Text.Small>
                  </Text>
                  <Button.List align="right">
                    <Button icon="credit-card" color="success">
                      PAY: {userRegistrationDetails.sanction_fee_currency}
                    </Button>
                  </Button.List>
                </Card.Footer>
              </Card>
            ) : ''}

          {(isCoach || isEventOrganizer || isAdmin) && (
            <StudentRecentCamps
              studentId={Number(studentId)}
              currentCampId={userRegistrationDetails.camp_id}
            />
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={12} md={8} lg={8}>
          <Card>
            <Card.Status
              className={
                'bg-' +
                STATUS_ICON_COLOR_MAPPING.get(userRegistrationDetails.status)
                  ?.color
              }
            />
            <Card.Header>
              {!isStudentOverride && (
                <Button
                  icon="activity"
                  className={'float-left mr-2 text-muted'}
                  size="sm"
                  onClick={() => setIsActivityModalOpen(true)}
                  color="white"
                >
                  <span className="d-none d-lg-inline">Activity</span>
                </Button>
              )}
              {userRegistrationDetails?.status === 'Confirmed' &&
                ((!isStudentOverride &&
                  userRegistrationDetails?.campScoreCards) ||
                  (isStudentOverride &&
                    userRegistrationDetails.userScoreCard)) && (
                  <>
                    <Button
                      icon="file-text"
                      className={'float-left mr-2 text-muted'}
                      size="sm"
                      onClick={toggle}
                      color="white"
                    >
                      <span className="d-none d-lg-inline">Score Card</span>
                    </Button>
                    <StudentTeamProgressionModal
                      studentId={studentId}
                      teamId={userRegistrationDetails?.team_id}
                      isOpen={isOpen}
                      toggle={toggle}
                      userRegistrationDetails={userRegistrationDetails}
                    />
                  </>
                )}
              {!isOrg && !isStudentOverride && isEdittingRating ? (
                <input
                  autoFocus
                  className={'float-right mr-2'}
                  min={0}
                  max={10}
                  type="number"
                  value={rating}
                  onChange={(e) => {
                    const value = Number(e.target.value)
                    if (value > 10) {
                      setRating(10)
                    } else if (value < 0) {
                      setRating(0)
                    } else {
                      setRating(value)
                    }
                  }}
                  onBlur={async () => {
                    updateRating()
                  }}
                  onKeyUp={(event) => {
                    switch (event.keyCode) {
                      case 13:
                        updateRating()
                        break
                      case 27:
                        setIsEdittingRating(false)
                        break
                    }
                  }}
                />
              ) : (
                !isOrg &&
                !isStudentOverride &&
                userRegistrationDetails?.campScoreCards && (
                  <Button
                    icon="star"
                    size="sm"
                    color="white"
                    className={rating > 0 ? 'text-info' : 'text-secondary'}
                    onClick={() => setIsEdittingRating(true)}
                    disabled={
                      moment().diff(
                        moment(userRegistrationDetails?.end),
                        'days'
                      ) > 1
                    }
                  >
                    {rating > 0 && (
                      <span className="ml-1">Event Rating: {rating}</span>
                    )}
                  </Button>
                )
              )}
              <Card.Options>
                {/* depreciated, dont delete
                <UserRegistrationLogHistoryButton />
              */}

                {/*EDIT STATUS HERE*/}
                {userRegistrationDetails && (
                  <UserRegistrationStatusDropdown
                    campId={userRegistrationDetails?.camp_id}
                    registrationStart={userRegistrationDetails?.start}
                    status={userRegistrationDetails?.status}
                    teamId={userRegistrationDetails?.team_id}
                    studentId={userRegistrationDetails?.student_id}
                    userRegId={userRegId}
                    isEditable={
                      moment().diff(
                        moment(userRegistrationDetails?.end),
                        'days'
                      ) < 1
                    }
                  />
                )}
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row className="mb-5">
                <Grid.Col>
                  <Text>
                    <strong>
                      <CalendarLink
                        eventName={userRegistrationDetails?.camp_name}
                        eventId={Number(userRegistrationDetails?.camp_id)}
                      />
                    </strong>{' '}
                    // {userRegistrationDetails?.team_name}
                  </Text>
                  <p className="mb-0">
                    {moment(userRegistrationDetails?.start).utc().format('ll')}{' '}
                    {domainData.key === EHostnames.FAIRFIGHTS &&
                    [EEventTypes.tournmament, EEventTypes.fightNight].includes(
                      userRegistrationDetails.eventTypeId
                    ) ? (
                      ''
                    ) : (
                      <>
                        -{' '}
                        {moment(userRegistrationDetails?.end)
                          .utc()
                          .format('ll')}
                      </>
                    )}
                  </p>
                  <Text className="text-muted">
                    <i className="fe fe-clock mr-1" />
                    {moment(userRegistrationDetails?.start).utc().format('LT')}
                    {' - '}
                    {moment(userRegistrationDetails?.end).utc().format('LT')}
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col className="mb-3">
                  {userRegistrationDetails && (
                    <CampRequirements
                      userRegistrationDetails={userRegistrationDetails}
                    />
                  )}
                </Grid.Col>
              </Grid.Row>
              {userRegistrationDetails?.default_jump_price > 0 && (
                <Grid.Row>
                  <Grid.Col width={8}>
                    <Text>Jump Tickets</Text>
                    <Text.Small className="d-block text-muted">
                      {formatMoney(
                        userRegistrationDetails?.default_jump_price,
                        userRegistrationDetails.registration_currency
                      )}
                      {userRegistrationDetails?.default_jump_count > 1
                        ? ' per jump'
                        : ' per event'}
                    </Text.Small>
                  </Grid.Col>
                  <Grid.Col width={2}>
                    {(userRegistrationDetails.eventTypeId !== EEventTypes.competition && isEditting) ? (
                      <Form.Input
                        autoFocus
                        placeholder="0"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        onBlur={updateJumpQuantity}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            updateJumpQuantity()
                          }
                        }}
                      />
                    ) : ((userRegistrationDetails.eventTypeId !== EEventTypes.competition &&
                      (userRegistrationDetails?.default_jump_count > 1 ||
                      userRegistrationDetails?.jump_quantity > 0)) || isAdmin || isCoachSuperAdmin) ? (
                      <Button
                        color="secondary"
                        onClick={() => {
                          if (!isStudentOverride) setIsEditting(true)
                        }}
                      >
                        {userRegistrationDetails?.jump_quantity}
                      </Button>
                    ) : userRegistrationDetails.eventTypeId === EEventTypes.competition ? (
                      <h1>{userRegistrationDetails?.jump_quantity}</h1>
                    ) : ''}
                  </Grid.Col>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Col>
                  {!userRegistrationDetails.registration_groups &&
                    userRegistrationDetails?.user_registration_options.map(
                      (option) => (
                        <UserRegistrationOption
                          handleToggleModal={() =>
                            handleToggleModal(
                              EModalTypes.registrationOptionModal
                            )
                          }
                          registrationOption={option}
                          key={option.id}
                          registrationId={userRegId}
                          currency={
                            userRegistrationDetails.registration_currency
                          }
                        />
                      )
                    )}
                  {(isCoachSuperAdmin || isCoachAdmin) &&
                    userRegistrationDetails?.user_registration_options.length <=
                      0 &&
                    !isStudentOverride && (
                      <Button
                        icon="plus"
                        color="secondary"
                        onClick={() => {
                          if (!isStudentOverride)
                            handleToggleModal(
                              EModalTypes.registrationOptionModal
                            )
                        }}
                        className="mt-5"
                      >
                        OPTIONS
                      </Button>
                    )}
                </Grid.Col>
                {userRegistrationDetails?.confirmedSlots.length > 0 && (
                  <Grid.Col>
                    <div className="h6"></div>
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault()
                        handleToggleModal(EModalTypes.slotsConfirmedModal)
                      }}
                    >
                      {`${
                        userRegistrationDetails?.confirmedSlots.length
                      } slots confirmed / ${moment
                        .utc()
                        .startOf('day')
                        .add({
                          minutes:
                            userRegistrationDetails?.totalTime.total_duration
                        })
                        .format('H:mm')}`}
                    </Button>
                  </Grid.Col>
                )}
              </Grid.Row>
              <UpdateUserRegistrationOptionModal
                isModalOpen={isModalOpen.registrationOptionModal}
                toggleModal={() =>
                  handleToggleModal(EModalTypes.registrationOptionModal)
                }
                userData={{
                  teamId: userRegistrationDetails?.team_id,
                  campId: Number(userRegistrationDetails?.camp_id),
                  userRegistrationId: userRegId,
                  defaultJumpCount:
                    userRegistrationDetails?.default_jump_count ?? 1,
                  userRegistrationOptions:
                    userRegistrationDetails?.user_registration_options
                }}
              />
            </Card.Body>

            <Card.Footer>
              <Grid.Col className="pl-0">
                <Text className="font-weight-bold">Registered On:</Text>
                <p>
                  {moment(userRegistrationDetails?.created_on)
                    .locale(navigator.language)
                    .format('dddd, LLL')}
                </p>
                <p>
                  Confirmation Code:{' '}
                  <strong>{userRegistrationDetails?.registration_code}</strong>
                </p>
              </Grid.Col>
            </Card.Footer>
            {isAdmin && (
              <Card.Footer className="bg-light">
                <Card.Options className="float-right">
                  <Tag className="cursor-pointer">+</Tag>
                </Card.Options>
              </Card.Footer>
            )}
          </Card>

          {!isCohost && (
            <RegistrationPayments
              hasPaymentsHistory={hasPaymentsHistory}
              outstanding={outstanding / 100}
              totalPaid={totalPaid / 100}
              userRegistrationDetails={userRegistrationDetails}
            />
          )}

          {!isStudentOverride && <CRMMessagesList />}

          <StudentNotesList
            studentId={Number(studentId)}
            registrationId={userRegId}
            teamId={userRegistrationDetails?.team_id}
          />

          {userRegistrationDetails && userRegId && (
            <>
              <ConfirmedSlotsModal
                userRegistrationDetails={userRegistrationDetails}
                isModalOpen={isModalOpen.slotsConfirmedModal}
                toggleModal={() =>
                  handleToggleModal(EModalTypes.slotsConfirmedModal)
                }
              />
            </>
          )}

          {activeQuestions.length > 0 &&
            moment(userRegistrationDetails?.end).isAfter() && (
              <Card>
                <Card.Status className="bg-primary" />
                <Card.Header>
                  <Card.Title>
                    <i className="fe fe-help-circle text-primary mr-2" />
                    Registration Questionaire
                    {isStudentOverride && (
                      <span className="text-muted ml-2">
                        (click answers to edit)
                      </span>
                    )}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {activeQuestions.map(
                    (question: Types.RegistrationQuestion) => (
                      <UserRegistrationQuestionItem
                        key={question.id}
                        question={question}
                        userRegId={userRegId}
                        studentId={Number(userRegistrationDetails?.student_id)}
                        teamId={Number(userRegistrationDetails?.team_id)}
                      />
                    )
                  )}
                </Card.Body>
              </Card>
            )}
          <RegistrationGroups
            campEnd={userRegistrationDetails.end}
            studentId={userRegistrationDetails.student_id}
            campId={userRegistrationDetails.camp_id}
            registrationId={userRegId}
            teamId={userRegistrationDetails.team_id}
            disciplineId={userRegistrationDetails.disciplineId}
            roleId={userRegistrationDetails.roleId}
            eventTypeId={
              userRegistrationDetails
                ? userRegistrationDetails.eventTypeId
                : null
            }
            currency={userRegistrationDetails.registration_currency}
            paymentDescription={`${userRegistrationDetails.camp_name}`}
            paymentEmail={userRegistrationDetails.email}
            isRegistrationGroups={!!userRegistrationDetails.registration_groups}
            paypal={userRegistrationDetails.paypal}
            venmo={userRegistrationDetails.venmo}
          />
          <RegistrationActivityListModal
            registrationId={userRegId}
            isOpen={isActivityModalOpen}
            setIsOpen={setIsActivityModalOpen}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  )
}
export default UserRegistration
