import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { GET_REGISTERED_STUDENTS } from '../../../../graphql/GET_REGISTERED_STUDENTS'
import { GET_REGISTERED_STUDENTS as GET_REGISTERED_STUDENTS_TYPE } from '../../../../graphql/types/GET_REGISTERED_STUDENTS'
import { Types } from '../../../../types/graphql'

const SEARCH_LIMIT = 10

interface CampParticipantSearchFieldProps {
  onChange: any
  defaultValue: number
  isDisabled?: boolean
  autoFocus?: boolean
  campId: number
  status?: string
}

const CampParticipantSearchField = ({
  onChange,
  defaultValue,
  isDisabled,
  autoFocus,
  campId,
  status,
}: CampParticipantSearchFieldProps) => {
  const [selectedMember, setSelectedMemeber] = useState(
    Number(defaultValue || 0)
  )

  useEffect(() => {
    setSelectedMemeber(defaultValue || 0)
  }, [defaultValue])

  const { loading, data, error } = useQuery<GET_REGISTERED_STUDENTS_TYPE>(
    GET_REGISTERED_STUDENTS,
    {
      variables: {
        campId: campId
      },
      fetchPolicy: 'network-only'
    }
  )

  const studentOptions = useMemo(() => {
    if (!loading && data) {
      return data[Object.keys(data)[0]]
        .filter(
          (student) =>
            student.status === 'Confirmed' ||
            student.status === 'Pending' ||
            student.status === 'Accepted'
        )
        .map((student: Types.RegisteredStudents) => ({
          label: `${student.first_name} ${student.last_name}`,
          value: student.student_id,
          user_registrations_id: student.user_registrations_id
        }))
    }
    return []
  }, [data, loading])
  return (
    <Select
      options={studentOptions}
      autoFocus={autoFocus}
      onChange={(e) => {
        onChange(e)
        setSelectedMemeber(e.value)
      }}
      isDisabled={isDisabled}
      value={studentOptions.find((member) => selectedMember === member.value)}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
      }}
      menuPortalTarget={document.body}
    />
  )
}

export default observer(CampParticipantSearchField)
